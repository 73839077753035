// extracted by mini-css-extract-plugin
export var column = "BlogPostCategoriesOptions__column__vcVKk";
export var flex = "BlogPostCategoriesOptions__flex__mamTx";
export var flexColumn = "BlogPostCategoriesOptions__flexColumn__PNvAi";
export var gap1 = "BlogPostCategoriesOptions__gap1__Sfbcy";
export var gap2 = "BlogPostCategoriesOptions__gap2__IULGV";
export var gap3 = "BlogPostCategoriesOptions__gap3__hrZ0N";
export var gap4 = "BlogPostCategoriesOptions__gap4__fleAQ";
export var gap5 = "BlogPostCategoriesOptions__gap5__YSCN7";
export var popoverBody = "BlogPostCategoriesOptions__popoverBody__R0OEx";
export var popoverBodyLink = "BlogPostCategoriesOptions__popoverBodyLink__yEQF7";
export var popoverBodyLinkIcon = "BlogPostCategoriesOptions__popoverBodyLinkIcon__Yo8zK";
export var row = "BlogPostCategoriesOptions__row__nf4AJ";